// return the user data from the session storage
//import axios from 'axios'
import {instance as axios} from "./axiosConfig";
// export const localeFunc = (number, index) => {
//   // number: the timeago / timein number;
//   // index: the index of array below;
//   // totalSec: total seconds between date to be formatted and today's date;
//   return [
//     ["à l'instant", 'dans un instant'],
//     ['il y a %s secondes', 'dans %s secondes'],
//     ['il y a 1 minute', 'dans 1 minute'],
//     ['il y a %s minutes', 'dans %s minutes'],
//     ['il y a 1 heure', 'dans 1 heure'],
//     ['il y a %s heures', 'dans %s heures'],
//     ['il y a 1 jour', 'dans 1 jour'],
//     ['il y a %s jours', 'dans %s jours'],
//     ['il y a 1 semaine', 'dans 1 semaine'],
//     ['il y a %s semaines', 'dans %s semaines'],
//     ['il y a 1 mois', 'dans 1 mois'],
//     ['il y a %s mois', 'dans %s mois'],
//     ['il y a 1 an', 'dans 1 an'],
//     ['il y a %s ans', 'dans %s ans'],
//   ][index];
// };
export const createPostRequest = (param, content) => {
  return axios({
    url: param,
    method: "POST",
    timeout: 8000,
    withCredentials: true,
    headers: {
      "Content-Type" : "application/json",
    },
    data: JSON.stringify(content)
  })
} ;

export const createFileDownloadRequest = (param, content) => {
  return axios({
    url: param,
    method: "POST",
    withCredentials: true,
    responseType: 'blob',
    data: JSON.stringify(content)
  })
}

export const b64toBlob = (b64Data, contentType='', sliceSize=512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, {type: contentType});
  return blob;
}
export const createGetRequest = (param) => {
  return axios({
    url: param,
    method: "GET",
    timeout: 8000,
    withCredentials: true,
    headers: {
      "Content-Type" : "application/json",
    }
  })
} ;

// export const setUserInfos = (data) => {
//     let userInfos = {} ;
//     let amountDue = 0 ;
//     userInfos["acc_id"] = data["acc_id"] ;
//     userInfos["acc_name"] = data["acc_txt"] ;
//     userInfos["soc_txt"] = data["soc_txt"] ;
//     userInfos["propart"] = data["ATR_A_PROPART"] !== undefined ? data["ATR_A_PROPART"] : "Professionnel" ;
//     userInfos["siren"] = data["ATR_A_SIREN"] !== undefined ? data["ATR_A_SIREN"] : "" ;
//     userInfos["extData"] = (data["ext_data"] !== undefined && data["ext_data"] !== null) ? data["ext_data"] : "" ;
//     for (let file of data["files"]){
//     for (let rec of file["records"]){
//         if (rec["letter_is_confirm"] !== true){
//         rec["amount"] = parseFloat(parseFloat(rec["amount"]).toFixed(2)) ;
//         amountDue += rec["amount"] ;
//         }
//     }
//     if (file["status"] === "S1_OPEN"){
//         userInfos["filerecord_id"] = file["file_filerecord_id"] ;
//     }
//     }
//     userInfos["amountDue"] = Math.round(amountDue * 100) / 100 ;
//     return userInfos;
// } ;

// export const setUserRecords = (data) => {
//   let records = [] ;

//   for (let file of data["files"]){
//       for (let rec of file["records"]){
//         if (rec["letter_is_confirm"] !== true){
// 				records.push(rec) ;
//         }
//       }

//   }
//   return {"records": records}
// } ;

// BUG : juste pour exemple
export const removeQueries = (browserHistory,query) => {
	console.dir(browserHistory) ;
    const location = Object.assign({}, browserHistory.getCurrentLocation());
    Object.assign(location.query, query);
    browserHistory.replace(location);
}

export const isEmpty = ( search ) => {
    return (Array.isArray(search) && search.length === 0) ||
        (search instanceof Object && Object.values(search).length === 0);
}

export const isNonEmpty = ( search ) => {
    return (Array.isArray(search) && search.length > 0) ||
        (search instanceof Object && Object.values(search).length > 0);
}

export const isNonEmptyArray = ( element ) => Array.isArray(element) && element.length > 0;
export const isNonEmptyObject = ( element ) => typeof element === 'object' && element !== null && !Array.isArray(element) && Object.keys(element).length > 0;

export const setLocalStorageItem = ( key, val ) => localStorage.setItem(key, val);
export const getLocalStorageItem = key => localStorage.getItem(key);
export const delLocalStorageItem = key => localStorage.removeItem(key);
export const clearLocalStorage = () => localStorage.clear();